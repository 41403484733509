


























































































































import { Component, Prop, Emit, Vue } from 'vue-property-decorator';
import BaseCheckbox from '@components/BaseCheckbox/BaseCheckbox.vue';
import BaseSelect from '@components/BaseSelect/BaseSelect.vue';

import '@pages/the-documents/scss/DocumentRow.scss';
import { namespace } from 'vuex-class';
import { ProjectDocumentsActions, ProjectDocumentsMutations } from '@store/modules/project-documents/Types';
import { ProjectMutations } from '@store/modules/project/Types';
import { IDocument, IStatus } from '@store/modules/project-documents/Interfaces';
import { IProject } from '@store/modules/project/Interfaces';
import { formatDate } from '@utility/formatDate.js';
import { ITask } from '@store/modules/project-tasks/interfaces/Interfaces';
import { createStatusConditions } from '@utility/createStatusConditions';

const NSUser = namespace('storeUser');
const NSProject = namespace('storeProject');
const NSDocuments = namespace('storeProjectDocuments');
const NSProjectTasks = namespace('storeProjectTasks');

@Component({
    name: 'DocumentRow',
    components: {
        BaseCheckbox,
        BaseSelect,
    }
})

export default class DocumentsGroup extends Vue {
    @Prop() documentData!: IDocument;
    @Prop() rowIndex!: number;
    @Prop() groupTitle!: string;
    @Prop({ default: false }) disableStatusSelect!: boolean;

    @NSProjectTasks.Getter('unfinishedTasks') unfinishedTasks!: ITask[];
    @NSDocuments.Getter('statuses') statuses!: IStatus[];
    @NSProject.Getter('projectData') projectData!: IProject;
    @NSUser.Getter('userRightOnlyGSN') userRightOnlyGSN!: boolean;
    @NSDocuments.Getter('selectedDocuments') selectedDocuments!: string[];
    @NSProject.Mutation(ProjectMutations.M_SET_DOCUMENTS) setDocument!: (payload: IDocument) => void;
    @NSDocuments.Mutation(ProjectDocumentsMutations.M_SET_SELECTED_DOCUMENTS) setSelectedDocuments!: (payload: string[]) => void;
    @NSDocuments.Action('A_GET_PROJECT_DOCUMENTS_FROM_ROOT') getProjectDocuments!: () => Promise<void>;
    @NSDocuments.Action(ProjectDocumentsActions.A_DOWNLOAD_FILES_ZIP) downloadFilesZip!: (payload: {
        data: {
            appId: string | number;
            ids: string;
        },
        nameArchive: string,
    }) => Promise<void>;
    @NSDocuments.Action(ProjectDocumentsActions.A_GET_COMMENTS) getComments!: (payload: {
        projectId: number;
        documentId: string;
    }) => void;
    @NSDocuments.Action(ProjectDocumentsActions.A_CHANGE_DOCUMENT_STATUS)
    changeDocumentStatus!: (payload: {
        projectId: number;
        documentId: string[];
        statusId: number | string;
    }) => Promise<void>;
    @NSDocuments.Action(ProjectDocumentsActions.A_GET_DOC_FILES) getDocFiles!:
        (payload: { appId: number, documentId: string }) => Promise<any>;
    selectedStatus = {} as IStatus;
    statusConditions: { [key: string]: string[] } = createStatusConditions(
        this.documentData?.type !== 'Исполнительная документация'
    );

    @Emit('show-unfinished-tasks')
    emitShowUnfinishedTasks(): ITask[] {
        return this.unfinishedTasksOfDocument;
    }

    get actionsAvailableForGSNRight() {
        if (this.userRightOnlyGSN) {
            return this.groupTitle === 'Предписание';
        }
        return true;
    }

    get statusOptions() {
        if (this.activeStatus !== 'Статус') {
            const statusValues: string[] = this.statusConditions[this.activeStatus];
            const output:IStatus[] = [];
            this.statuses.forEach((item) => {
                if (statusValues && statusValues.indexOf(item.title) !== -1) {
                    output.push(item);
                }
            })
            return output;
        }
        return this.statuses;
    }

    get formattedRowIndex() {
        const index = this.rowIndex + 1;
        return index < 10 ? `0${index}` : index;
    }

    get rowTitle() {
        return this.documentData?.title || '';
    }

    get rowAuthor() {
        return this.documentData?.author ? this.documentData.author.fullName : '';
    }

    get rowDate() {
        if (this.documentData.createDate) {
            const date = new Date(this.documentData.createDate);
            let day: string | number = date.getDate();
            let month: string | number = date.getMonth() + 1;
            if (day < 10) {
                day = '0' + day;
            }
            if (month < 10) {
                month = '0' + month;
            }
            return `${day}.${month}.${date.getFullYear()}`;
        }
        return '';
    }

    get documentIsSelected() {
        return this.selectedDocuments.indexOf(this.documentData?.uuid) !== -1;
    }

    get activeStatus() {
        if (this.selectedStatus && this.selectedStatus.title) {
            return this.selectedStatus.title;
        } else if (this.documentData && this.documentData.status) {
            return this.documentData.status.title;
        }
        return 'Статус';
    }

    get colorForStatus() {
        let statusTitle = '';
        if (this.selectedStatus && this.selectedStatus.title) {
            statusTitle = this.selectedStatus.title;
        } else if (this.documentData && this.documentData.status) {
            statusTitle = this.documentData.status.title;
        }
        const activeStatusObjects = this.statuses.filter(status => status.title === statusTitle);
        if (activeStatusObjects[0]) {
            return activeStatusObjects[0].color;
        }
        return 'transparent';
    }

    get nameArchive() {
        let dateNow = formatDate({date: new Date(), format: 'dd_mm_yyyy'});
        return `Documentation_archive_${dateNow}.zip`;
    }

    get unfinishedTasksOfDocument(): ITask[] {
        return this.unfinishedTasks.filter((task: ITask) => {
            if (task.subject?.uuid === this.documentData?.uuid) {
                return true;
            }
            return false;
        });
    }

    get countUnfinishedTasksOfDocument(): number | string {
        let countTasks: number = 0;
        countTasks = this.unfinishedTasksOfDocument.length;
        return countTasks === 0 ? '-' : countTasks;
    }

    createStatusConditions(complete: boolean = false): { [key: string]: string[] } {
        return {
            'Новый': [
                'На согласовании', 'В разработке', complete && 'Завершено',
            ],
            'На доработке': [
                'На согласовании', 'Отменено',
            ],
            'На согласовании': [
                'На доработке', 'Согласовано', 'Отменено', complete &&  'Завершено',
            ],
            'В разработке': [
                'На согласовании', 'Согласовано', 'Отменено', complete && 'Завершено',
            ],
            'Согласовано': [
                'На доработке', 'Отменено', complete && 'Завершено',
            ],
            'Отменено': [
                'На доработке', complete && 'Завершено',
            ],
            ...(complete) && {'Завершено': [
                'На доработке',
            ]},
        } as { [key: string]: string[] };
    }

    checkBeforeDelete() {
        this.$emit('check-before-delete', [this.documentData]);
    }

    onSelectStatus(e) {
        this.selectedStatus = e;

        this.changeDocumentStatus({
            projectId: this.projectData.id,
            documentId: [this.documentData.uuid],
            statusId: e.id,
        })
            .then(() => {
                this.getProjectDocuments();
            });

        let docData = JSON.parse(JSON.stringify(this.documentData));
        docData.status = {
            id: e.id,
            title: e.title,
        }
        this.setDocument(docData);
    }

    onSelectDocument() {
        if (this.selectedDocuments.indexOf(this.documentData?.uuid) === -1) {
            this.setSelectedDocuments(this.selectedDocuments.concat([this.documentData.uuid]));
        } else {
            this.selectedDocuments.splice(this.selectedDocuments.indexOf(this.documentData.uuid), 1);
            this.setSelectedDocuments(this.selectedDocuments);
        }
    }

    openComments() {
        this.$emit('open-comment-popup', {
            projectId: this.projectData.id,
            documentId: this.documentData.uuid,
            documentTitle: this.documentData.title,
            documentRowTitle: this.groupTitle,
        });

        this.getComments({
            projectId: this.projectData.id,
            documentId: this.documentData.uuid,
        })
    }

    downloadFiles() {
        let loading = true;
        let nameDocument = this.documentData.title;
        let countDocuments = 1;
        let errorDownload = '';

        this.getDocFiles({
            appId: this.projectData.id,
            documentId: this.documentData.uuid,
        })
        .then((files) => {
            if (files.length > 1) {
                this.$emit('confirm-download-documents', {
                    loading,
                    nameDocument,
                    countDocuments,
                });
                this.downloadFilesZip({
                    data: {
                        appId: this.projectData.id,
                        ids: this.documentData.uuid,
                    },
                    nameArchive: this.nameArchive,
                })
                    .catch((error) => {
                        errorDownload = error;
                    })
                    .finally(() => {
                        loading = false;
                        this.$emit('confirm-download-documents', {
                            loading,
                            nameDocument,
                            countDocuments,
                            errorDownload,
                        });
                    });
            } else {
                files.forEach((item) => {
                    window.open(item.link);
                });
            }
        });
    }

    showDocuments() {
        this.getDocFiles({
            appId: this.projectData.id,
            documentId: this.documentData.uuid,
        })
        .then((files) => {
            this.$emit('open-documents-list', {
                documents: files,
                docData: this.documentData,
            });
        });
    }

    openCreateTaskPopup() {
        this.$emit('open-create-task-popup', this.documentData);
    }
};
