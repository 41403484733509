export const createStatusConditions = (complete = false) => {
    return {
        'Новый': [
            'На согласовании', 'В разработке', complete && 'Завершено',
        ],
        'На доработке': [
            'На согласовании', 'Отменено',
        ],
        'На согласовании': [
            'На доработке', 'Согласовано', 'Отменено', complete &&  'Завершено',
        ],
        'В разработке': [
            'На согласовании', 'Согласовано', 'Отменено', complete && 'Завершено',
        ],
        'Согласовано': [
            'На доработке', 'Отменено', complete && 'Завершено',
        ],
        'Отменено': [
            'На доработке', complete && 'Завершено',
        ],
        ...(complete) && {'Завершено': [
            'На доработке',
        ]},
    };
}
